import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import Link from 'basics/Link'
import styles from './CardContainer.module.scss'
import { ClassNames } from '../../services/classname.service'
import Headline from '../../components/Headline'
import Spacer from '../../basics/Spacer'
import Card from '../../components/Card'
import Heading from '../../basics/Heading'
import LargeIcon from '../../basics/LargeIcon/index'
import FlexContainer from '../../containers/FlexContainer/FlexContainer'
import { ExtractLink } from 'design-system/src/services/data.service'
import { PickFirst } from 'services/utils'
import Address from 'components/Address'
import Button from 'basics/Button/Button'

const Layouts = Object.freeze({
  1: {
    size: '320px',
    className: 'normal'
  },
  2: {
    size: '500px',
    className: 'large'
  },
  3: {
    size: '320px',
    className: 'normal'
  },
  4: {
    size: '320px',
    className: 'normal'
  }
})

//View Article, all Success Stories would be View Case Study, all podcasts would be Access Podcast, all blogs would be View Blog

const LinkTitles = {
  'Case Study': 'View Case Study',
  Podcast: 'Access Podcast',
  Article: 'View Article',
  Blog: 'View Blog'
}

const CardContainer = ({
  className,
  children,
  gapSize = 32,
  layout,
  headline,
  subheadline,
  link,
  type,
  linkText,
  cards = [],
  data = {},
  showLink = true,
  narrow = true,
  targetPage,
  embed,
  id
}) => {
  if (data.cards) {
    cards = data.cards
  }
  if (data.headline) {
    headline = data.headline
  }
  if (data.subheadline) {
    subheadline = data.subheadline
  }
  if (!layout && cards.length) {
    layout = cards.length
  }
  if (!layout && children) {
    layout = children.length
  }
  const config = Layouts[layout] || 3
  const cta = PickFirst(data.cta) || {}

  return (
    <>
      {headline && (
        <>
          <Headline
            headline={headline}
            link={cta.linkUrl || PickFirst(cta.linkedItem)?.url || targetPage || '#'}
            linkText={cta.text || linkText}
            narrow={narrow}
            id={headline.replace(/ /g, '-').toLowerCase()}
          >
            <div
              className={styles['subheadline']}
              dangerouslySetInnerHTML={{ __html: subheadline }}
            />
          </Headline>
          <Spacer size={8} />
        </>
      )}
      <FlexContainer
        id={id}
        gridSize="auto-fit"
        gridElementSize={config.size}
        gridGap={gapSize}
        className={ClassNames([
          `justify-content-center`,
          styles['ds-card-container'],
          styles[`ds-card-container--${config.className}`],
          layout === 5 ? styles[`ds-card-container--five`] : null,
          className
        ])}
      >
        {children}
        {cards.map((card) => {
          let targetPage = Array.isArray(card) ? ExtractLink(card) : card.targetPage
          if (Array.isArray(targetPage)) {
            targetPage = ExtractLink(targetPage)
          }
          const withCta =
            ((targetPage && targetPage.url) || (card.linkUrl && card.linkText)) &&
            showLink === true &&
            card.type !== 'location' &&
            !card.hasChildren
            const theCard = <Card
              key={nanoid()}
              type={card.cardType?.toLowerCase() || card.type}
              image={card.image}
              embed={card.embed || card.cardMapEmbed}
              embedTitle={card.headline}
              imageAlt={card.imageAlt || PickFirst(card.image)?.metadata?.alt || ''}
              subtitle={card.subtitle}
              withCta={withCta}
              className={ClassNames([
                styles['ds-card-container__card'],
                `ds-card-container__card--${config.className}`
              ])}
            >
          {card.icon && card.icon.length > 0 && (
            <>
              <LargeIcon
                svg={card.icon[0]?.svg}
                color={card.iconColor[0]?.shortName || 'blue'}
              />
              <Spacer size={3} />
            </>
          )}
          {card.headline &&
          <>
            <Heading level={2} className="mb-3">
              {(card.linkUrl || (targetPage && targetPage.url)) ? (
                <>
                  <span className="card-chevron">{card.headline}</span>
                </>
              ) : (
                <>
                  {card.headline}
                </>
              )}
            </Heading>
            <Spacer size={3} />
          </>
          }
          {card.subheadline && (
            <div
              className={ClassNames([
                styles['ds-card-container__text'],
                type !== 'news' ? 'mb-5' : 'mb-2 text-align-center',
                card.subtitle ? 'line-clamp' : ''
              ])}
              dangerouslySetInnerHTML={{
                __html: `${card.subheadline}`
              }}
            />
          )}

          {card.address && (
            <Address
              {...card.address}
              state={card.address.state}
              phoneNumber={card.phoneNumber || null}
            />
          )}

          {card.targetPage && card.showButton && card.type === 'location' && (
            <div className="mt-8 mb-5">
                <Button type="primaryLight">View Location</Button>
            </div>
          )}

          {card.hasChildren && (
            <div className="mt-3 mb-4 font-small">
              {card.children.map((child) => {
                return (
                  <div key={nanoid()} className="mb-1">
                  {(card.linkUrl || (targetPage && targetPage.url)) ? (
                    <span className="card-chevron">{child.name}</span>
                  ) : (
                    <>
                      {child.name}
                    </>
                  )}
                  </div>
                )
              })}
            </div>
          )}

        </Card>

          return (
            <Fragment key={ nanoid() }>
            {(card.linkUrl || (targetPage && targetPage.url)) ? (
              <Link
                href={card.linkUrl ? card.linkUrl : `/${targetPage.url}`}
                rel={card.linkUrl ? 'noopener noreferral' : null}
                external={card.linkUrl ? true : false}
                className={'clickable-card-link'}
                >
                {theCard}
              </Link>
            ) : (
              <>
              {theCard}
              </>
            )}
            </Fragment>
          )
        })}
      </FlexContainer>
    </>
  )
}

CardContainer.propTypes = {
  headline: PropTypes.string,
  subheadline: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  cards: PropTypes.array
}

export default CardContainer
